import React, { Component } from "react";
import { Link } from "react-scroll";
import "./Navbar.scss";

class Navbar extends Component {
  render() {
    return (
      
        <nav className="navbar">
          <div className="divLogoNav">
            <span className="colorLetter">D</span>river
            <span className="colorLetter">Rea</span>ction
            <span className="colorLetter">M</span>odel
          </div>
          <div
            className={this.props.activateNav ? "navLinks-active" : "navLinks"}
          >
            <Link
              className="link-inactive"
              activeClass="link-active"
              to="DreaM"
              spy={true}
              smooth={true}
              offset={-50}
            >
              The Model
            </Link>
            <Link
              className="link-inactive"
              activeClass="link-active"
              to="tutorials"
              spy={true}
              smooth={true}
              offset={-60}
            >
              Videos
            </Link>
            <Link
              className="link-inactive"
              activeClass="link-active"
              to="sourceCode"
              spy={true}
              smooth={true}
              offset={-30}
            >
              Source Code
            </Link>
            <Link
              className="link-inactive"
              activeClass="link-active"
              to="publications-section"
              spy={true}
              smooth={true}
              offset={-60}
            >
              Publications
            </Link>
            <Link
              className="link-inactive"
              activeClass="link-active"
              to="about"
              spy={true}
              smooth={true}
              offset={-80}
            >
              About
            </Link>
          </div>
          <div
            className={this.props.activateNav ? "btn active" : "btn not-active"}
            onClick={this.props.onNavMenu}
          >
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </div>
        </nav>
   
    );
  }
}
export default Navbar;
