import React, { Component } from "react";
import "./Publications.scss";
import Table from "react-bootstrap/Table";

class Publications extends Component {
  state = {
    publications: [
      {
        titel:
          "Predicting the impact on road safety of an intersection AEB at urban intersections. Using a novel virtual test field for the assessment of conflict prevention between cyclists/pedelecs and cars",
        journal: "Transportation Research Interdisciplinary Perspectives",
        date: "Januar 2023",
        downloadLink: "http://doi.org/10.1016/j.trip.2022.100728",
      },
      {
        titel:
          "What Do Traffic Simulations Have to Provide for Virtual Road Safety Assessment? Human Error Modeling in Traffic Simulations",
        journal: "IEEE Transactions on Intelligent Transportation Systems",
        date: "November 2022",
        downloadLink: "http://doi.org/10.1109/tits.2022.3220961",
      },
      {
        titel:
          "Approaching intersections: Gaze behavior of drivers depending on traffic, intersection type, driving maneuver, and secondary task involvement",
        journal:
          "Transportation Research Part F: Traffic Psychology and Behaviour",
        date: "November 2022",
        downloadLink: "https://doi.org/10.1016/j.trf.2022.09.010",
      },
      {
        titel:
          "Report on layout of the traffic simulation and trial design of the evaluation (Deliverable D2.16)",
        journal: "Report (AutoDrive project)",
        date: "17.12.2021",
        downloadLink: "https://doi.org/10.26128/2021.244",
      },
      {
        titel:
          "Report on design of modules for the stochastic traffic simulation (Deliverable D4.20)",
        journal: "Report (AutoDrive project)",
        date: "17.12.2021",
        downloadLink: "https://doi.org/10.26128/2021.245",
      },
      {
        titel:
          "Report on integration of the stochastic traffic simulation (Deliverable D5.13)",
        journal: "Report (AutoDrive project)",
        date: "17.12.2021",
        downloadLink: "https://doi.org/10.26128/2021.246",
      },
      {
        titel:
          "Report on validation of the stochastic traffic simulation: Part A (Deliverable D6.23)",
        journal: "Report (AutoDrive project)",
        date: "17.12.2021",
        downloadLink: "https://doi.org/10.26128/2021.242",
      },
      {
        titel:
          "Report on validation of the stochastic traffic simulation: Part B (Deliverable D6.23)",
        journal: "Report (AutoDrive project)",
        date: "17.12.2021",
        downloadLink: "https://doi.org/10.26128/2021.243",
      },
    ],
  };

  renderPublication = (publication, index) => {
    return (
      <tr key={index}>
        <td>
          <a
            className="titel"
            href={publication.downloadLink}
            target="_blank"
            rel="noreferrer"
          >
            {publication.titel}
          </a>
        </td>
        <td>{publication.journal}</td>
        <td>{publication.date}</td>
      </tr>
    );
  };
  render() {
    return (
      <div className="publications-section" id="publications">
        <div data-aos="fade-up" className="publications-container">
          <h1 className="headline-Background">Publications</h1>
          <Table responsive striped bordered hover variant="dark">
            <thead className="thead-dark">
              <tr>
                <th>Title</th>
                <th>Publication Type</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>{this.state.publications.map(this.renderPublication)}</tbody>
          </Table>
        </div>
      </div>
    );
  }
}

export default Publications;
