import React, { Component } from "react";
import "./SourceCode.scss";
import GetTheCode from "../../Images/SourceCode/Get_the_Code_new_animated.svg";
import DreaMLogo from "../../Images/Logos/Logo_Dream_op.svg";
import OpenPASSLogoWhite from "../../Images/Logos/OpenPASS_Logo_white_c.svg";
import madeWithUnity from "../../Images/Logos/unity_white.png";

class SourceCode extends Component {
  state = {};
  render() {
    return (
      <div className="source-Code-section" id="sourceCode">
        <div className="content-container">
          <div className="sources-container  ">
            <div data-aos="fade-up" className="source-container-text">
              <img className="getTheCode" src={GetTheCode} alt="Get the code" />
            </div>
            <div data-aos="fade-up" className="picSource-container">
              <div className="picSource-div textBackground ">
                <a
                  className="linkg"
                  href="https://github.com/ChristianSiebke/DReaM-Driver-Reaction-Model"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h1>DReaM embedded into openPASS</h1>
                  <img
                    className="source-dreaMLogo"
                    src={DreaMLogo}
                    alt="DReaM Logo"
                  />
                  <div className="comingSoon">
                    <h2>comming soon</h2>{" "}
                  </div>
                </a>
              </div>
              <div className="picSource-div textBackground ">
                <a
                  className="linkg"
                  href="https://github.com/vincentadamthefirst/DReaM-Visualization"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h1>DReaM-Visualization</h1>
                  <img
                    className="source-madeWithUnity"
                    src={madeWithUnity}
                    alt="unity Logo"
                  />
                </a>
              </div>
            </div>
            <div className="reference inset-1">
              <div className="text">
                <h2>When citing DReaM/DReaM-Visualization:</h2>
                <code>
                  <div className="indent">
                    <span> @ARTICLE&#123;Siebke2022,</span> <br />
                    <div>
                      <span>author</span>=
                      <span>
                        &#123;Siebke, Christian and Mai, Marcus and Prokop,
                        G&#123;\"&#123;u&#125;&#125;nther&#125;,
                      </span>
                    </div>
                    <div>
                      <span>journal</span>=
                      <span>
                        &#123;IEEE Transactions on Intelligent Transportation
                        Systems&#125;,
                      </span>
                    </div>
                    <div>
                      <span>title</span>=
                      <span>
                        &#123;What Do Traffic Simulations Have to Provide for
                        Virtual Road Safety Assessment? Human Error Modeling in
                        Traffic Simulations&#125;,
                      </span>
                    </div>
                    <div>
                      <span>year</span>=<span>&#123;2022&#125;,</span>
                    </div>
                    <div>
                      <span>pages</span>=<span>&#123;1-18&#125;,</span>
                    </div>
                    <div>
                      <span>doi</span>=
                      <span>&#123;10.1109/TITS.2022.3220961&#125;&#125;</span>
                    </div>
                  </div>
                </code>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SourceCode;
