import React, { Component } from "react";
import "./Footer.scss";
import TULogoDark from "../../Images/Logos/TU_Dresden/TUD_Logo_schwarz.png";
import gitDarkLogo from "../../Images/Logos/GitHub/GitHub-Black-64px.png";
import researchGateLogo from "../../Images/Logos/researchgateLogo.svg";
import dreamLogoBlack from "../../Images/Logos/Logo_Dream_black.svg";

class Footer extends Component {
  state = {};
  render() {
    return (
      <div className="footer">
        <div className="Legal-Notice">
          <h2> Legal Notice</h2>
          <p>
            Christian Siebke | Weiße Gasse 2 | 01067 Dresden <br />
            Christian.siebke@tu-dresden.de
          </p>
        </div>
        <div className="LogoFooter">
          <img
            className="dreamLogoBlack"
            src={dreamLogoBlack}
            alt="Logo DReaM"
          />
        </div>
        <div className="linksFooter">
          <div>
            <a
              href="https://www.researchgate.net/profile/Christian_Siebke2"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="LinkLogo"
                src={researchGateLogo}
                alt="Link research gate"
              ></img>
            </a>
            <a
              href="https://tu-dresden.de/bu/verkehr/iad/kft"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="LinkLogo"
                src={TULogoDark}
                alt="Link Technische Universität Dresden"
              ></img>
            </a>
            <a
              href="https://github.com/ChristianSiebke"
              target="_blank"
              rel="noreferrer"
            >
              <img className="LinkLogo" src={gitDarkLogo} alt="GitHub"></img>
            </a>
          </div>
          <div className="freePik">
            <a
              className="freePik"
              href="https://www.freepik.com/vectors/abstract"
            >
              some pictures are designed by Freepik: <br />
              www.freepik.com
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default Footer;
