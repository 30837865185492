import React, { Component } from "react";
import brainOutOfTheBoxAnimated from "../../Images/BrainOutOfTheBox/BrainOutOfBoxAnimated_bubblesOutOfBox.svg";
import trafficSimulationPhone from "../../Images/TrafficSimulation/TrafficSimulationPhone.jpg";
import trafficSimulation from "../../Images/TrafficSimulation/TrafficSimulation.jpg";
import DReaMModelSketch from "../../Images/TrafficSimulation/ModelSketchC.svg";
import testfieldApproach from "../../Images/TrafficSimulation/TestField_approach_PNGCity.svg";
import assessmentSafetySystem from "../../Images/TrafficSimulation/AssessmentSafetySystemC.svg";
import "./DReaM.scss";

class DReaM extends Component {
  state = {
    zoomImage: false,
    zoomText: false,
    tagImage: "",
    tagHeading: "",
    tagText: "",
  };

  handleCloseTile = () => {
    this.setState({
      zoomImage: false,
      zoomText: false,
      tagImage: "",
      tagHeading: "",
      tagText: "",
    });
  };

  handleOnImage = (tag) => {
    this.setState({
      zoomImage: true,
      tagImage: tag,
      zoomText: false,
      tagText: "",
    });
  };

  handleOnText = (tagHeading, tagText) => {
    this.setState({
      zoomText: true,
      tagHeading: tagHeading,
      tagText: tagText,
      zoomImage: false,
      tagImage: "",
    });
  };

  render() {
    return (
      <div id="DreaM">
        <div className="dream-section">
          <div className="aboutContent">
            <div className="element">
              <div
                className="center aboutTile textBackground brainOutofTheBox"
                data-aos="fade-right"
                onClick={() => this.handleOnImage(brainOutOfTheBoxAnimated)}
              >
                {" "}
                <img
                  className="image brainOutofTheBox"
                  src={brainOutOfTheBoxAnimated}
                  alt="Driver behaviour model"
                ></img>{" "}
              </div>
              <div
                data-aos="fade-left"
                className="aboutTile textBackground"
                onClick={() =>
                  this.handleOnText(
                    document.getElementById("DReaMHeading").innerText,
                    document.getElementById("DReamText").innerText
                  )
                }
              >
                <h1 id="DReaMHeading">
                  DReaM: A new cognitve driver behaviour model
                </h1>
                <p id="DReamText" className="truncate">
                  {" "}
                  The Driver Reaction Model (DReaM) focuses on simulating
                  "realistic" traffic flow to create a virtual test field for
                  investigating safety-related issues. In doing so, DReaM
                  represents the entire spectrum of realistic traffic
                  situations, including critical situations, collisions, but
                  also non-critical traffic situations. It was developed for
                  urban traffic, specifically intersection scenarios. DReaM is a
                  computationally time-optimized model for the rough estimation
                  of changes in traffic events. The model attempts to represent
                  the most important cognitive processes of humans in order to
                  predict realistic behavior while also simplifying the
                  processes to such an extent that the simulation time is
                  reduced to a minimum. Christian Siebke is developing DReaM as
                  a part of his PhD Thesis.
                </p>
              </div>
            </div>
            <div className="element">
              <div
                data-aos="fade-right"
                className="aboutTile textBackground"
                onClick={() =>
                  this.handleOnText(
                    document.getElementById("HDIWHeading").innerText,
                    document.getElementById("HDIWText").innerText
                  )
                }
              >
                <h1 id="HDIWHeading">How does it work?</h1>
                <p id="HDIWText" className="truncate">
                  DReaM is a cognitive model. Cognition refers to all processes
                  in which sensory information is transformed and processed.
                  After the perception of visual information in the sensor
                  module, the perceived information is stored in the cognitive
                  map. Information that is not updated by perception will be
                  extrapolated on the present data. Based on the available
                  information of the cognitive map, the situation is interpreted
                  and important decision states such as the right-of-way or
                  collision state are evaluated. Subsequently, the next gaze
                  state, route decision and driving maneuver is derived.
                  Finally, the driving manoeuvre is implemented.
                </p>
              </div>
              <div
                data-aos="fade-left"
                className="aboutTile textBackground center"
                onClick={() => this.handleOnImage(DReaMModelSketch)}
              >
                <img
                  className="image"
                  src={DReaMModelSketch}
                  alt="Sketch of driver behaviour model"
                ></img>
              </div>
            </div>
            <div className="element column-reverse">
              <div
                data-aos="fade-right"
                className="aboutTile textBackground center"
                onClick={() => this.handleOnImage(testfieldApproach)}
              >
                <img
                  className="testfieldApproach"
                  src={testfieldApproach}
                  alt="Approach traffic simuation (virtual test field)"
                ></img>
              </div>
              <div
                data-aos="fade-left"
                className="aboutTile textBackground"
                onClick={() =>
                  this.handleOnText(
                    document.getElementById("ApplicationHeading").innerText,
                    document.getElementById("ApplicationText").innerText
                  )
                }
              >
                <h1 id="ApplicationHeading">Application of DReaM</h1>
                <p id="ApplicationText" className="truncate">
                  The main purpose of DReaM is to provide a tool for studying
                  the safety impact of advanced driver assistance systems (ADAS)
                  or highly automated driving (HAD). Integrating DReaM into a
                  traffic simulation allows for endurance and/or scenario
                  studies to be conducted. Potentially critical scenarios can be
                  found within endurance simulations (scenario generation).
                  Within scenario simulations, certain scenarios can be
                  re-simulated or expanded. Detailed investigations of single
                  natural driving data (NDS), simulator or traffic observation
                  scenarios can be carried out. DReaM is applicable in the early
                  design phase of the safety system and can be used during
                  development to provide the developer with a fast feedback
                  loop.
                </p>
              </div>
            </div>{" "}
            <div className="element">
              <div
                data-aos="fade-right"
                className="aboutTile textBackground"
                onClick={() =>
                  this.handleOnText(
                    document.getElementById("AssessmentHeading").innerText,
                    document.getElementById("AssessmentText").innerText
                  )
                }
              >
                <h1 id="AssessmentHeading">Assessment of new safety systems</h1>
                <p id="AssessmentText" className="truncate">
                  The assessment approach is based on the comparison of two
                  Monte Carlo simulation runs: the baseline (simulation without
                  safety system) and the prognosis (simulation with safety
                  system). Due to the introduction of the safety system within
                  the prognosis, the traffic event changes in terms of e.g.
                  collision frequency or collision speed. Based on this change,
                  the effectiveness of the safety system can be evaluated.
                </p>
              </div>
              <div
                data-aos="fade-left"
                className="aboutTile textBackground center"
                onClick={() => this.handleOnImage(assessmentSafetySystem)}
              >
                <img
                  className="image "
                  src={assessmentSafetySystem}
                  alt="Assessment safety system"
                ></img>
              </div>
            </div>
            <div className="element column-reverse">
              <div
                data-aos="fade-right"
                className=" center aboutTile textBackground"
                onClick={() => this.handleOnImage(trafficSimulation)}
              >
                <img
                  className="image"
                  src={trafficSimulationPhone}
                  alt="traffic"
                ></img>
              </div>
              <div
                data-aos="fade-left"
                className=" textBackground aboutTile"
                onClick={() =>
                  this.handleOnText(
                    document.getElementById("SoftwareHeading").textContent,
                    document.getElementById("SoftwareText").innerHTML
                  )
                }
              >
                <h1 id="SoftwareHeading">Software</h1>
                <p id="SoftwareText" className="truncate">
                  {" "}
                  DReaM is currently developed in the openPASS{" "}
                  <a
                    href="https://openpass.eclipse.org/"
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                  >
                    (open Platform for Assessment of Safety System){" "}
                  </a>
                  framework. The driver behavior model integrated in the traffic
                  simulation openPASS can be already downloaded{" "}
                  <a
                    href="https://github.com/ChristianSiebke"
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                  >
                    here.{" "}
                  </a>
                  A separate C++ library containing only DReaM will also be
                  available soon for implementation in other traffic simulations
                  and simulators.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.zoomImage || this.state.zoomText ? "zoomContainer" : ""
          }
        >
          <div
            className={
              this.state.zoomImage || this.state.zoomText ? "closeSign" : ""
            }
            onClick={() => this.handleCloseTile()}
          >
            <div
              className={
                this.state.zoomImage || this.state.zoomText ? "crossLine1" : ""
              }
            ></div>
            <div
              className={
                this.state.zoomImage || this.state.zoomText ? "crossLine2" : ""
              }
            ></div>
          </div>
          <img
            className={this.state.zoomImage ? "zoomedTile" : ""}
            src={this.state.tagImage}
            alt=""
          ></img>
          <div className={this.state.zoomText ? "zoomedTile" : ""}>
            <h1>{this.state.tagHeading}</h1>
            <p dangerouslySetInnerHTML={{ __html: this.state.tagText }} />
          </div>
        </div>
      </div>
    );
  }
}

export default DReaM;
