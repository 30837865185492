import React, { Component } from "react";
import "./About.scss";
import picChristianCompressed from "../../Images/About/profil_Christian_Siebke_BlackWhite_Compressed.jpg";
import picJanSommer from "../../Images/About/JanSommer.jpg";
import picChristianGaerber from "../../Images/About/ChristianGaerber.jpg";
import picVincentAdam from "../../Images/About/VincentAdam.jpg";

import TULogo from "../../Images/Logos/TU_Dresden/TUD_Logo_weiss.png";
import gitLightLogo from "../../Images/Logos/GitHub/GitHubLightLogo.png";
import researchGateLogo from "../../Images/Logos/researchgateLogo.svg";

class About extends Component {
  state = {};
  render() {
    return (
      <div className="about-section" id="about">
        <div className="profiles">
          <div data-aos="fade-up" className="profile-main">
            <div className="picChristian-container">
              <img
                className="picChristian"
                src={picChristianCompressed}
                alt="Christian Siebke"
              />
            </div>
            <div className="profile-main-container textBackground">
              <div className="profile-main-text">
                <h1>Christian Siebke</h1>
                <p>
                  I am a research assistant at the Chair of Automobile
                  Engineering at Dresden University of Technology since
                  September 2017. My research areas are road safety and road
                  user modelling. As part of my dissertation, I am developing
                  the driver behavior model "DReaM". My aim is to provide a
                  framework for simulating the behavior of road users. The focus
                  of DReaM is simulation in minimal time under the condition of
                  sufficient accuracy to perform safety-relevant studies.
                </p>
              </div>
              <div className="linksContact">
                <a
                  href="https://www.researchgate.net/profile/Christian_Siebke2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="LinkLogo"
                    src={researchGateLogo}
                    alt="ResearchGate"
                  ></img>
                </a>
                <a
                  href="https://tu-dresden.de/bu/verkehr/iad/kft"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="LinkLogo"
                    src={TULogo}
                    alt="Lehrstuhl für Kraftfahrzeugtechnik"
                  ></img>
                </a>
                <a
                  href="https://github.com/ChristianSiebke"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="LinkLogo"
                    src={gitLightLogo}
                    alt="GitHub"
                  ></img>
                </a>
              </div>
            </div>
          </div>
          <div className="student-profiles">
            <div data-aos="fade-up" className="profile">
              <div className="picStudent-container">
                <img
                  className="picStudents"
                  src={picVincentAdam}
                  alt="Vincent Adam"
                />
              </div>
              <div className="box">
                <div className="textBackground ">
                  <h2>Vincent Adam</h2>
                  <p>Student assistant</p>
                </div>
              </div>
            </div>
            <div data-aos="fade-up" className="profile">
              <div className="picStudent-container">
                <img
                  className="picStudents"
                  src={picChristianGaerber}
                  alt="Christian Gärber"
                />
              </div>
              <div className=" box">
                <div className="textBackground">
                  <h2>Christian Gärber</h2>
                  <p>Student assistant</p>
                </div>
              </div>
            </div>
            <div data-aos="fade-up" className="profile">
              <div className="picStudent-container">
                <img
                  className="picStudents"
                  src={picJanSommer}
                  alt="Jan Sommer"
                />
              </div>
              <div className="box">
                <div className="textBackground">
                  <h2>Jan Sommer</h2>
                  <p>Student assistant</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
