import React, { Component } from "react";
import landingPageVideoCompressed from "../Videos/LandingPage/LandingPageC.mp4";

import dreamLogo from "../Images/Logos/Logo_Dream_op.svg";
import TULogo from "../Images/Logos/TU_Dresden/TUD_Logo_weiss.png";
import gitLightLogo from "../Images/Logos/GitHub/GitHubLightLogo.png";
import researchGateLogo from "../Images/Logos/researchgateLogo.svg";
import "./LandingPage.scss";
import madeWithUnity from "../Images/Logos/unity_white.png";

class LandingPage extends Component {
  state = {
    videoPlayed: false,
    fadeOutAnimationState: "",
    videoModeLoop: false,
  };

  render() {
    return (
      <section className="landingPage" onClick={this.props.onClick}>
        <div className="foreground">
          <video className="landingVideo " autoPlay muted loop controls>
            <source src={landingPageVideoCompressed} type="video/mp4" />
          </video>
        </div>
        <div className="containerLP">
          <img className="dreamLogo" src={dreamLogo} alt="DReaM Logo"></img>
          <h1 className="h1-LP">Making Traffic Simulations more realistic</h1>
        </div>
        <div className="linksLP">
          <a
            href="https://www.researchgate.net/profile/Christian_Siebke2"
            target="_blank "
            rel="noreferrer"
          >
            <img
              className="LinkLogoLP"
              src={researchGateLogo}
              alt="DReaM Research Gate"
            ></img>
          </a>
          <a
            href="https://tu-dresden.de/bu/verkehr/iad/kft"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="LinkLogoLPTU"
              src={TULogo}
              alt="DReaM Technische Universität Dresden"
            ></img>
          </a>
          <a
            href="https://github.com/ChristianSiebke"
            target="_blank"
            rel="noreferrer"
          >
            <img className="LinkLogoLP" src={gitLightLogo} alt="GitHub"></img>
          </a>
        </div>
        <img
          className="madeWithUnity"
          src={madeWithUnity}
          alt="Unity Logo"
        ></img>
      </section>
    );
  }
}

export default LandingPage;
