import React, { Component } from "react";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";
import "react-awesome-slider/src/components/react-awesome-frame/styles.scss";
import "./Tutorials.scss";

import trafficFlowBeta from "../../Videos/Trafficsim_Dream_Beta.mp4";
import carRightCyclistStraight from "../../Videos/car_cyclist_AEB_Driver_Splitscreen.mp4";

class Tutorials extends Component {
  state = {};
  render() {
    return (
      <div className="tutorial-section" id="tutorials">
        <div data-aos="fade-up" className="content">
          <AwesomeSlider className="aws-btn">
            <div data-src={carRightCyclistStraight}></div>
            <div data-src={trafficFlowBeta}></div>
          </AwesomeSlider>
        </div>
      </div>
    );
  }
}

export default Tutorials;
