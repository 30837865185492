import React, { Component } from "react";
import AOS from "aos";
import LandingPage from "./components/LandingPage.jsx";
import Body from "./components/Body.jsx";
import Navbar from "./components/Navbar.jsx";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import "./App.scss";


class Main extends Component {
  state = {
    activateNav: false,
  };
  handleCloseNavbar = () => {
    this.setState({ activateNav: false });
  };
  handleOnNavMenu = () => {
    this.setState({ activateNav: !this.state.activateNav });
  };

  componentDidMount() {
    AOS.init({
      duration: 1000,
      offset: 300,
      delay: 200,
      startEvent: "load",
      once: true,
      disable: function () {
        var maxWidth = 500;
        return window.innerWidth < maxWidth;
      },
    });
  }

  render() {
    return (
      <div className="webSite">
        <CookieConsent
          flipButtons
          cookieName="cookieConsentDriver-model.de"
          location="bottom"
          buttonText="I accept"
          enableDeclineButton
          declineButtonText="I decline"
          style={{ background: "#2B373B" }}
          buttonStyle={{
            background: "#90D832",
            fontWeight: "bolder",
          }}
          declineButtonStyle={{
            background: "#DB584A",
            fontWeight: "bolder",
          }}
          expires={150}
          onAccept={() => {
            window._paq.push(["rememberConsentGiven"]);
          }}
          onDecline={() => {
            window._paq.push(["requireCookieConsent"]);
          }}
        >
          This website uses cookies to enhance the user experience
          <Link
            className="link-active"
            to="/PrivacyPolicy"
            target="_blank"
            rel="noreferrer"
          >
            {""} Privacy Policy
          </Link>
        </CookieConsent>
        <LandingPage onClick={this.handleCloseNavbar} />
        <Navbar
          onNavMenu={this.handleOnNavMenu}
          activateNav={this.state.activateNav}
        />
        <Body onClick={this.handleCloseNavbar} />
      </div>
    );
  }
}

export default Main;
