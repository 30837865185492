import React, { Component } from "react";
import DReaM from "./Content/DReaM.jsx";
import SourceCode from "./Content/SourceCode.jsx";
import About from "./Content/About.jsx";
import Publications from "./Content/Publications.jsx";
import Footer from "./Content/Footer.jsx";
import Tutorials from "./Content/Tutorials.jsx";
import "./Body.scss";

class Body extends Component {
 
  componentDidMount() {
       document.documentElement.style.setProperty(
         "--vh", 
         window.outerHeight + 100+ "px"
       );
       document.documentElement.style.setProperty(
         "--vw",
         window.innerWidth + "px"
       );
  }

  render() {
    return (
      <div className="bodyBackground" onClick={this.props.onClick}>
        <DReaM />
        <Tutorials />
        <SourceCode />
        <Publications />
        <About />
        <Footer />
      </div>
    );
  }
}

export default Body;
