import React, { Component } from "react";
import {
  Routes,
  Route
} from 'react-router-dom';
import PrivacyPolicy from "./PrivacyPolicy.jsx"
import Main from "./Main.jsx"


class App extends Component {
  render() {
    return ( 
      <Routes>
        <Route exact path='' element={< Main />}></Route>     
         <Route exact path='/PrivacyPolicy' element={< PrivacyPolicy />}></Route>          
      </Routes>     
);
  }
}

export default App;
